import React, { useMemo, useState } from 'react'
import { navigate } from 'gatsby'
import { TextureLoader, Euler, Quaternion } from 'three'
import { useFrame, useLoader } from 'react-three-fiber'
import { easeInOutQuad } from '../lib/easings'

// TODO:
// - design solution to density of pins

const Marker = ({node, index}) => {
	const textureAddress = `/shapes/${node.shape.key}.png`
	const texture = useLoader(TextureLoader, textureAddress)

	const [hovered, setHovered] = useState(false)
	const [mouseDragged, setMouseDragged] = useState(false)
	const [pointerDown, setPointerDown] = useState(false)

	const onClick = (e) => {
		if (pointerDown && !mouseDragged) {
			// console.log("navigate to", node.slug.current)
			navigate(`/work/${node.slug.current}`)
		}
		else {
			setPointerDown(false)
			return
		}

	}

	const onPointerOver = (e) => {
		setHovered(true)
	}

	const onPointerOut = (e) => {
		setHovered(false)
	}

	const onPointerMove = (e) => {
		if (pointerDown) {
			setMouseDragged(true)
		}
	}

	const onPointerDown = (e) => {
		e.stopPropagation()
		setPointerDown(true)
		setMouseDragged(false)
	}

	const hoverDurationInFrames = 10
	const [hoverCurrentFrame, setHoverCurrentFrame] = useState(0)

	const currentEase = useMemo( () => {
		return easeInOutQuad(hoverCurrentFrame / hoverDurationInFrames)
	}, [hoverCurrentFrame])

	const scale = useMemo( () => {
		return 5 * currentEase + 5
	}, [currentEase])

	const scaledPosition = useMemo( () => {
		return 2 * currentEase + 53
	}, [currentEase])

	useFrame(() => {
		// if `hovered`, increase scale each frame until max scale
		// previous scale on sprite attrib: scale={hovered ? [10, 10, 1] : [5, 5, 1]}
		// previous position on sprite attrib: position={hovered ? [55, 0, 0] : [53, 0, 0]}
		if (hovered && hoverCurrentFrame < hoverDurationInFrames) {
			setHoverCurrentFrame(hoverCurrentFrame + 1)
		}
		else if (!hovered && hoverCurrentFrame > 0) {
			setHoverCurrentFrame(hoverCurrentFrame - 1)
		}
	})

	const position = useMemo(() => {
		const q = new Quaternion()
		q.setFromEuler(new Euler(0, node.coordinates.lng * (Math.PI / 180), node.coordinates.lat * (Math.PI / 180), "YZX"))
		return q
	}, [node])

	return (
		<object3D quaternion={position}>
			<sprite
				scale={[scale, scale, 1]}
				position={[scaledPosition, 0, 0]}
				onPointerUp={onClick}
				onPointerDown={onPointerDown}
				onPointerOver={onPointerOver}
				onPointerOut={onPointerOut}
				onPointerMove={onPointerMove}
			>
				<spriteMaterial attach="material" map={texture} color={node.category.catColor.color.hex} />
			</sprite>
		</object3D>
	)
}

export default Marker
