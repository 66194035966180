import React, { useState, Suspense } from 'react'
import { Canvas, Dom, useThree } from 'react-three-fiber'
import Globe from './globe'
import { ZoomInIcon, ZoomOutIcon } from './icon/zoom-icons'
import 'pepjs'

import styles from './globe-layout.module.css'

const Loader = () => {
	return (
		<Dom center>
			<svg width="50" height="50">
				<circle id="dot" cx="0" cy="0" r="10" fill="#0b3ecf" transform="translate(25 25)">
					<animateTransform
						id="transform1"
						attributeName="transform"
						type="scale"
						additive="sum"
						from="0.5 0.5"
						to="1 1"
						begin="0s;transform2.end"
						dur="0.25s"
						keySplines=".5 0 .5 1;"
					/>

					<animateTransform
						id="transform2"
						attributeName="transform"
						type="scale"
						additive="sum"
						from="1 1"
						to="0.5 0.5"
						begin="transform1.end"
						dur="0.25s"
						keySplines=".5 0 .5 1;"
					/>
				</circle>
			</svg>
		</Dom>
	)
}

const GlobeLayout = ({ nodes, globeTextureUrl }) => {
	const [zoomIn, setZoomIn] = useState(false)
	const [zoomOut, setZoomOut] = useState(false)

	return (
		<div className={styles.root}>
			{typeof window !== `undefined` ? (
				<Canvas pixelRatio={window.devicePixelRatio || 1}>
					<Suspense fallback={<Loader />}>
						<Globe
							nodes={nodes}
							globeTextureUrl={globeTextureUrl}
							zoomIn={zoomIn}
							zoomOut={zoomOut}
						/>
					</Suspense>
				</Canvas>
			) : null}
			<button className={styles.button}
				onPointerDown={e => setZoomIn(true)} onPointerUp={e => setZoomIn(false)}><ZoomInIcon /></button>
			<button className={styles.button}
				onPointerDown={e => setZoomOut(true)} onPointerUp={e => setZoomOut(false)}><ZoomOutIcon /></button>
		</div>
	)
}

export default GlobeLayout
