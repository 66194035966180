import React, { useRef, useMemo, useState, useLayoutEffect } from 'react'
import { TextureLoader, Euler, Quaternion } from 'three'
// import * as OrbitControls from 'three/examples/jsm/controls/OrbitControls'
import * as OrbitControls from '../lib/OrbitControls'
import { useFrame, useLoader, useThree, extend } from 'react-three-fiber'
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import Marker from './globe-marker'

extend(OrbitControls)

const Globe = ({ nodes, globeTextureUrl, zoomIn, zoomOut }) => {
	const globeTextureAddress = imageUrlFor(buildImageObj(globeTextureUrl)).url()
	const globeTexture = useLoader(TextureLoader, globeTextureAddress)

	const groupRef = useRef()
	const controls = useRef()
	const camera = useRef()
	const { setDefaultCamera, gl, size } = useThree()

	const [{ isVertical, verticalCamPosition }, setVerticalObj] = useState({
		isVertical: false,
		verticalCamPosition: 150
	})

	useMemo(() => {
		if (size.width/size.height < 1) {
			setVerticalObj({
				isVertical: true,
				verticalCamPosition: 250 - ( ( ( (size.width/size.height) - 0.5 ) / 0.5 ) * 100 )
			})
			if (controls.current) {
				controls.current.saveState()
			}
		}
		else {
			setVerticalObj({
				isVertical: false,
				verticalCamPosition: 150
			})
			if (controls.current) {
				controls.current.saveState()
			}
		}
	}, [size])

	useLayoutEffect(() => {
		if (controls.current) {
			controls.current.saveState()
		}
	}, [isVertical, verticalCamPosition])

	useLayoutEffect(() => {
		void setDefaultCamera(camera.current)

		if (controls.current) {
			controls.current.reset()
			controls.current.saveState()
		}
	}, [zoomIn, zoomOut])

	useFrame(() => {
		// camera.current.updateMatrixWorld()
		if (zoomIn) {
			controls.current.dollyOut()
			controls.current.saveState()
		}
		else if (zoomOut) {
			controls.current.dollyIn()
			controls.current.saveState()
		}
		else {
			controls.current.update()
			controls.current.saveState()
		}
	})

	const onPointerDown = (e) => {
		e.stopPropagation()
	}

	return (
		<>
			<perspectiveCamera
				ref={camera}
				position={
					// responsive globe size in vertical formats
					isVertical ? [0, 0, verticalCamPosition]
					: [0, 0, 150]}
			/>
			{camera.current && (
				<orbitControls
					ref={controls}
					args={[camera.current, gl.domElement]}
					autoRotate
					autoRotateSpeed={1}
					enableDamping
					dampingFactor={0.1}
					rotateSpeed={0.5}
					enablePan={false}
					maxDistance={512}
					minDistance={96} />
			)}
			<group ref={groupRef}>
				<mesh onPointerDown={onPointerDown}>
					<sphereGeometry attach="geometry" args={[50, 128, 128]} />
					<meshBasicMaterial attach="material" map={globeTexture ? globeTexture : null} color="white" />
				</mesh>
				{nodes && nodes.map((node, i) => (
					<Marker key={node.id} node={node} index={i} />
				))}
			</group>
		</>
	)
}

export default Globe
