import React from 'react'

import styles from './zoom-icons.module.css'

export const ZoomInIcon = () => (
	<svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg">
		<title>Zoom In</title>
		<desc>Zoom in button</desc>
		<g>
			<line
				className={styles.stroke}
				x1="0"
				y1="24"
				x2="48"
				y2="24"
			/>
			<line
				className={styles.stroke}
				x1="24"
				y1="0"
				x2="24"
				y2="48"
			/>
		</g>
	</svg>
)

export const ZoomOutIcon = () => (
	<svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg">
		<title>Zoom Out</title>
		<desc>Zoom out button</desc>
		<g>
			<line
				className={styles.stroke}
				x1="0"
				y1="24"
				x2="48"
				y2="24"
			/>
		</g>
	</svg>
)
